* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html, body {
    height: 100%;
}

body {
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

header {
    margin: .5rem 0;
}

summary {
    cursor: pointer;
}

.player-and-subtitles {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 1rem;
    flex: 1;
    min-height: 0;
}

editor-view-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 0;
}

editor-view-wrapper-buttons {
    margin: 0 0 1rem 0;
}

editor-view-wrapper .ProseMirror {
    overflow-y: scroll;
    flex: 1;
}

block {
    display: block;
    margin: 0 0 1rem 0;
}

block-ui {
    display: flex;
    justify-content: space-between;
    white-space: normal;
}

block-buttons {
    display: block;
}

block-lines {
    display: block;
}

block.cursor block-lines {
    border: 1px solid black;
}

block.current-time block-lines {
    background: yellow;
}

.already-open-alert {
    position: fixed;
    display: grid;
    place-content: center;
    top: 0;
    left: 0;
    background: white;
    height: 100vh;
    width: 100vw;
}

.init-overlay {
    cursor: pointer;
    position: fixed;
    display: grid;
    place-content: center;
    top: 0;
    left: 0;
    background: rgba(100, 100, 100, .9);
    height: 100vh;
    width: 100vw;
}

.template {
    display: none;
}

.alert {
    color: red;
}

.load-media-local {
    display: none;
}

.open-subtitle {
    display: none;
}

.button, button {
    font-size: 100%;
    padding: .5rem 1rem;
    color: rgba(0,0,0,.8);
    border: transparent;
    background-color: #e6e6e6;
    text-decoration: none;
    border-radius: 2px;
    display: inline-block;
    line-height: normal;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
}

button[disabled] {
    color: rgba(0,0,0,.2);
    cursor: not-allowed;
}